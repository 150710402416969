// reducer.jsx
import { UPDATE_TEXT } from './actions';

const initialState = {
  text: '',
};

const textReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    default:
      return state;
  }
};

export default textReducer;

// const initialState = {
//   uploading: false,
//   sending: false,
//   uploadError: null,
//   sendError: null,
// };

// const contentReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "UPLOAD_CONTENT":
//       return {
//         ...state,
//         uploading: true,
//         uploadError: null,
//       };
//     case "UPLOAD_CONTENT_SUCCESS":
//       return {
//         ...state,
//         uploading: false,
//       };
//     case "UPLOAD_CONTENT_FAILURE":
//       return {
//         ...state,
//         uploading: false,
//         uploadError: action.payload,
//       };
//     case "SEND_CONTENT":
//       return {
//         ...state,
//         sending: true,
//         sendError: null,
//       };
//     case "SEND_CONTENT_SUCCESS":
//       return {
//         ...state,
//         sending: false,
//       };
//     case "SEND_CONTENT_FAILURE":
//       return {
//         ...state,
//         sending: false,
//         sendError: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default contentReducer;

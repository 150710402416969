import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Styles from './CourseSlider.module.scss';
import { Link } from 'react-router-dom';
import 'skeleton-screen-css';
import { RenderStarsDependOnCourseRating } from '../../common/func/func';
import { RenderCourseDirectionBlockChain } from '../../common/func/func';
import { CourseSliderLoadingAnimation } from '../../common/func/LoadingAnimations';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1824 },
    items: 5,
  },
  Medium: {
    breakpoint: { max: 1824, min: 1324 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1324, min: 864 },
    items: 3,
  },
  SmallDesktop2: {
    breakpoint: { max: 864, min: 340 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

const Item = ({
  courseImage,
  courseName,
  courseTitle,
  courseRating,
  courseAuthor,
  courseCategory,
  courseHasDiscount,
  reviewAmount,
  Price,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const TitleMaxLenght = 50;

  const truncateName = (name) => {
    if (name.length <= TitleMaxLenght) {
      return name;
    } else {
      return name.slice(0, TitleMaxLenght) + '...';
    }
  };

  const Discount = (disc, Price) => {
    if (disc === 1) {
      return (
        <div>
          <h3 className={Styles.Item__Price}>{Price}$</h3>
        </div>
      );
    } else {
      return (
        <div className={Styles.PriceWithDiscount}>
          <h3 className={Styles.Item__Price}>${9.99}</h3>
          <h5 className={Styles.Item__Price}>
            <s>${Price}</s>
          </h5>
        </div>
      );
    }
  };

  return (
    <div
      className={`${Styles.Item} ${isHovered ? Styles.ItemHovered : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={`/course/${courseName}`}>
        <img src={courseImage} alt='Course Thumbnail' />
        <div className={Styles.Item__details}>
          <h3 className={Styles.Item__CourseName}>{truncateName(courseTitle)}</h3>
          <h3 className={Styles.Item__CourseAuthor}>
            {courseAuthor} &#183; {courseCategory}
          </h3>
          <div className={Styles.Item__Rating}>
            <h3>{courseRating}</h3>
            {RenderStarsDependOnCourseRating(courseRating)}
            <h4>{reviewAmount} reviews</h4>
          </div>
          {Discount(courseHasDiscount, Price)}
        </div>
      </Link>
    </div>
  );
};

export default function CourseSlider({ sliderTitle, items }) {
  return (
    <div className={Styles.Root}>
      <h1 className={Styles.Slider__title}>{sliderTitle}</h1>
      {/* <RenderCourseDirectionBlockChain /> */}
      <Carousel responsive={responsive}>
        {items && items.length > 0 ? (
          items.map((course, index) => (
            <div key={index}>
              <Item
                courseImage={course.preview_source ?? 'N/A'}
                courseName={course.name ?? 'N/A'}
                courseRating={course.rating ?? 'N/A'}
                courseAuthor={course.instructor ?? 'N/A'}
                courseCategory={course.category ?? 'N/A'}
                reviewAmount={course.students ?? 'N/A'}
                courseTitle={course.name ?? 'N/A'}
                courseHasDiscount={course.courseWithDiscount ?? 'N/A'}
                Price={course.price ?? 'N/A'}
              />
            </div>
          ))
        ) : (
          <div className={Styles.LoadingAnimation}>
            <CourseSliderLoadingAnimation />
            <CourseSliderLoadingAnimation />
            <CourseSliderLoadingAnimation />
            <CourseSliderLoadingAnimation />
            <CourseSliderLoadingAnimation />
          </div>
        )}
      </Carousel>
    </div>
  );
}

import React, { useState } from 'react';
import Header from '../../components/header/header';
import Styles from './ResetPassword.module.scss';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RegistrationEmailForm } from '../../common/func/Forms';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <div className={Styles.Todo}>
        <div className={Styles.Root}>
          <h1 className={Styles.FormTitle}>{t('text_reset_password_title')}</h1>
          <RegistrationEmailForm setEmail={setEmail} />
          <button className={Styles.Button}>{t('text_continue')}</button>
          <button className={Styles.BackButton}>
            <Link to='/login'>{t('text_back')}</Link>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

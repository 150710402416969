// import { createStore } from 'redux';
import textReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({ reducer: textReducer });

export default store;

// const store = createStore(textReducer);

// // store.js
// import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const store = createStore(rootReducer, applyMiddleware(thunk));

// export default store;

// actions.js

export const UPDATE_TEXT = 'UPDATE_TEXT';

export const updateText = (text) => ({
  type: UPDATE_TEXT,
  payload: text,
});

export const uploadContent = (content) => {
  return async (dispatch) => {
    try {
      // Perform upload to server
      const response = await fetch('https://your-upload-endpoint', {
        method: 'POST',
        body: content,
        // Add necessary headers, like 'Content-Type', etc.
      });

      // Assuming your server responds with some confirmation
      const data = await response.json();

      // Dispatch action to handle successful upload
      dispatch(uploadContentSuccess(data)); // You can dispatch other actions as needed
    } catch (error) {
      // Dispatch action to handle upload failure
      dispatch(uploadContentFailure(error)); // You can dispatch other actions as needed
    }
  };
};

export const sendContent = (content) => {
  return async (dispatch) => {
    try {
      // Perform sending content to server
      const response = await fetch('https://your-send-endpoint', {
        method: 'POST',
        body: JSON.stringify(content),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Assuming your server responds with some confirmation
      const data = await response.json();

      // Dispatch action to handle successful send
      dispatch(sendContentSuccess(data)); // You can dispatch other actions as needed
    } catch (error) {
      // Dispatch action to handle send failure
      dispatch(sendContentFailure(error)); // You can dispatch other actions as needed
    }
  };
};

const uploadContentSuccess = (data) => ({
  type: 'UPLOAD_CONTENT_SUCCESS',
  payload: data,
});

const uploadContentFailure = (error) => ({
  type: 'UPLOAD_CONTENT_FAILURE',
  error: true,
  payload: error,
});

const sendContentSuccess = (data) => ({
  type: 'SEND_CONTENT_SUCCESS',
  payload: data,
});

const sendContentFailure = (error) => ({
  type: 'SEND_CONTENT_FAILURE',
  error: true,
  payload: error,
});

import React, { useState } from 'react';
import Header from '../../components/header/header';
import { Link } from 'react-router-dom';
import Styles from './UserRegistrationForm.module.scss';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

import { ValidateRegistrationUserForm } from '../../common/func/Validator';
import { RegistrationFormValidateUserData } from '../../common/func/func';
import { requestCreateNewUser } from '../../common/Services/Services';
import { RegistrationEmailForm } from '../../common/func/Forms';
import { RegistrationPasswordForm } from '../../common/func/Forms';
import { RegistrationUserNameForm } from '../../common/func/Forms';

function TermsSection() {
  const { t } = useTranslation();

  return (
    <div className={Styles.ReadTermsSection}>
      <h2>
        {t('text_sign_up_terms_agree')} <Link to='/terms'>{t('text_terms_of_use')}</Link>.
      </h2>
    </div>
  );
}

// function RegistrationButton({ first_name, last_name, email, password }) {
function RegistrationButton(validatedUser) {
  const validated = RegistrationFormValidateUserData(
    validatedUser.first_name,
    validatedUser.last_name,
    validatedUser.email,
    validatedUser.password,
  );
  const { t } = useTranslation();

  const handleCreateButton = () => {
    requestCreateNewUser(validated);
  };

  return (
    <div className={Styles.RegistrationButton}>
      <button onClick={handleCreateButton} type='submit'>
        {t('text_continue')}
      </button>
    </div>
  );
}

function LoginLinkRedirectionSection() {
  const { t } = useTranslation();

  return (
    <div className={Styles.AuthLink}>
      <a href='/login'>
        {t('text_have_account')} <span className={Styles.Purple}>{t('button_login')}</span>
      </a>
    </div>
  );
}

export default function RegistartionUserMenu() {
  const [Username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const validatedUser = ValidateRegistrationUserForm(Username, email, password);

  return (
    <>
      <Header />
      <div className={Styles.Todo}>
        <div className={Styles.Root}>
          <h1 className={Styles.MenuTitle}>{t('text_sign_up_title')}</h1>
          <RegistrationUserNameForm setUsername={setUsername} />
          <RegistrationEmailForm setEmail={setEmail} />
          <RegistrationPasswordForm setPassword={setPassword} />
          {/* <PasswordRequirementsSection /> */}
          <TermsSection />
          <RegistrationButton validatedUser={validatedUser} />
          <LoginLinkRedirectionSection />
        </div>
      </div>
      <Footer />
    </>
  );
}

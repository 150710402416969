import React from 'react';
import Styles from './CourseDirectionLinks.module.scss';
import { useTranslation } from 'react-i18next';

function Categorie({ Title, Link }) {
  return (
    <div className={Styles.Categorie}>
      <a href={Link}>
        <h2>{Title}</h2>
      </a>
    </div>
  );
}

export default function Topic() {
  const { t, i18n } = useTranslation();

  return (
    <div className={Styles.Root}>
      <h1 className={Styles.Title}>{t('text_what_you_inderested_in')}</h1>
      <div className={Styles.CategoriesWrapper}>
        <Categorie Title={t('category_development')} Link={'/courses/development'} />
        <Categorie Title={t('category_business')} Link={'/courses/business'} />
        <Categorie Title={t('category_languages')} Link={'/courses/languages'} />
        <Categorie Title={'React'} Link={'/courses/development/react'} />
        <Categorie Title={'JavaScript'} Link={'/courses/development/javascript'} />
        <Categorie Title={'Design'} Link={'/courses/design'} />
        <Categorie Title={'Rust'} Link={'/courses/development/rust'} />
        <Categorie Title={'Python'} Link={'/courses/development/python'} />
      </div>
    </div>
  );
}

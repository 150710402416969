import React, { useState, useEffect } from 'react';
import Styles from './Contact.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/AboutCompanyHeader/AboutCompanyHeader';
import ContactCarousel from './ContactCarousel/ContactCarousel';
import { CustomTextArea } from '../../common/func/CustomTextArea';
import { CustomForm } from '../../common/func/Forms';
import { SendEmailService } from '../../common/Services/Services';

function SendingEmailForm() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    // SendEmailService(firstName, lastName, email, message);

    setEmail('');
    setFirstName('');
    setLastName('');
    setMessage('');
  };

  return (
    <div className={Styles.SendingEmailRoot}>
      <div className={Styles.CustomerName}>
        <CustomForm
          Title={'First Name'}
          width={'300px'}
          height={'40px'}
          DataForSet={setFirstName}
          Value={firstName}
        />
        <CustomForm Title={'Last Name'} width={'300px'} Value={lastName} DataForSet={setLastName} />
      </div>
      <CustomForm Title={'Email'} width={'400px'} Value={email} DataForSet={setEmail} />

      <CustomTextArea DataForSet={setMessage} Value={message} Title={'Message'} />
      <button onClick={handleSendMessage} className={Styles.SendMessageButton}>
        Send Message
      </button>
    </div>
  );
}

function Contact() {
  useEffect(() => {
    document.title = 'Contact | Ednely';
  });

  return (
    <>
      <Header />

      <div className={Styles.Root}>
        <ContactCarousel />
        <SendingEmailForm />
      </div>

      <Footer />
    </>
  );
}

export default Contact;

import React, { useState, useEffect } from 'react';
import Title from './components/title/CoursePreviewTitle';
import Content from './components/content/content';
import Header from '../../components/header/header';
import Footer from '../../components/Footer/Footer';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { requestSpecificCourseByName } from '../../common/Services/Services';

function Course() {
  const { name } = useParams();
  console.log('Course:', name);

  const [course, setCourse] = useState(null);
  const [Modules, setModules] = useState(null);
  const navigate = useNavigate();

  console.log('> trying request', name);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://localhost:3002/api/courses/${name}`);
        setCourse(response.data);
        console.log('response: ', response.data);
      } catch (error) {
        console.error('Error fetching course data:', error.message);
        navigate('/course-not-found');
      }
    };

    fetchData();
  }, [name, navigate]);

  if (!course) {
    return null;
  }

  return (
    <div>
      <Header />
      <Title course={course} />

      <Content
        course={course}
        // price={course.price}
        price={9.99}
        hours={course.hours}
        modules={course.modules}
        topics={course.topics}
        tests={course.tests}
        certification={course.ceritification}
        learn_data={course.what_you_will_learn}
        curriculum={null}
        instructor={course.instructor}
      />
      <Footer />
    </div>
  );
}

export default Course;

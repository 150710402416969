import React, { useState } from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/Footer/Footer';
import Styles from './Login.module.scss';
import { Link } from 'react-router-dom';
import { LoginFormValidateUserData } from '../../common/func/func';
import { requestUserLogin } from '../../common/Services/AuthService';
import { RegistrationEmailForm } from '../../common/func/Forms';
import { RegistrationPasswordForm } from '../../common/func/Forms';
import { useTranslation } from 'react-i18next';
import { ValidateLoginUserForm } from '../../common/func/Validator';

function BottomLinks() {
  const { t } = useTranslation();

  return (
    <div className={Styles.BottomLinks}>
      <a href='/register'>{t('button_sign_up')}</a>
      <a href='/fp'>{t('button_reset_password')}</a>
    </div>
  );
}

function LoginButton({ email, password }) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = () => {
    ValidateLoginUserForm(email, password);
    const status = requestUserLogin({ email, password });

    switch (status) {
      case 401:
        setErrorMessage('Wrong email or password');
        break;
      case 404:
        setErrorMessage('User not found');
        break;
      // case 500: setErrorMessage('Internal server error, try latter'); break;
      default:
        setErrorMessage('Internal server error, try latter');
        break;
    }
  };

  return (
    <div className={Styles.LoginButton}>
      {errorMessage && (
        <h1 className={Styles.NotificationDiv} style={{ display: 'block' }}>
          {errorMessage}
        </h1>
      )}
      <button onClick={handleLogin}>{t('button_login')}</button>
    </div>
  );
}

export default function Authorization({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className={Styles.Root}>
        <div className={Styles.Authentication}>
          <h1 className={Styles.MenuTitle}>{t('text_login_welcome_back')}</h1>

          <RegistrationEmailForm setEmail={setEmail} />
          <RegistrationPasswordForm setPassword={setPassword} />
          <LoginButton email={email} password={password} />
          <BottomLinks />
        </div>
      </div>
      <Footer />
    </>
  );
}

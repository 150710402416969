import React, { useState, useEffect } from 'react';
import Header from '../../components/header/header';
import Styles from './Home.module.scss';
import CourseSlider from '../../components/Sliders/CourseSlider';
import BigSlider from '../../components/Sliders/BigSlider';
import Topic from './CourseDirectionLinks';
import Review from '../../components/Reviews/Review';
import Footer from '../../components/Footer/Footer';
import InfoBlock from './InformationSection';
import UserHelpSearch from './UserHelpSearch';
import NewsCarousel from './NewsCarousel';
import RenderWelcomeMessageForAuthUser from './WelcomeMessage';

import { requestCoursesSectionMightBeInterested } from '../../common/Services/Services';
import { requestCoursesSectionMostPopular } from '../../common/Services/Services';
import { requestCoursesSectionNew } from '../../common/Services/Services';
import CookieContent from '../../components/Cookie/CookieContent';
import { useTranslation } from 'react-i18next';

function Home() {
  const [isAuth, setIsAuth] = useState(false);
  const { t, i18n } = useTranslation();

  const [courseUserIterested, setCourseUserInterested] = useState([]);
  const [coursesMostPopular, setCoursesMostPopular] = useState([]);
  const [coursesNew, setCoursesNew] = useState([]);

  useEffect(() => {
    // ?
    const storedToken = localStorage.getItem('authToken');
    setIsAuth(!!storedToken);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const interestedCourses = await requestCoursesSectionMightBeInterested();
        const mostPopularCourses = await requestCoursesSectionMostPopular();
        const newCourses = await requestCoursesSectionNew();
        setCourseUserInterested(interestedCourses);
        setCoursesMostPopular(mostPopularCourses);
        setCoursesNew(newCourses);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className={Styles.Home}>
        <NewsCarousel />
        <RenderWelcomeMessageForAuthUser />

        <div className={Styles.HomeItemsWrapper}>
          <CourseSlider
            sliderTitle={t('courses_might_be_interested_in')}
            items={courseUserIterested}
          />
          <CourseSlider sliderTitle={t('courses_most_popular')} items={coursesMostPopular} />
          <BigSlider sliderTitle={t('courses_new')} items={coursesNew} />
          <Topic />
          <InfoBlock />
          <CourseSlider
            sliderTitle={t('courses_recommended_for_beginners')}
            items={courseUserIterested}
          />{' '}
          {/* todo request */}
          <CourseSlider
            sliderTitle={t('courses_highest_rating')}
            items={courseUserIterested}
          />{' '}
          {/* todo request */}
          <Review />
          <CourseSlider
            sliderTitle={t('courses_best_in_development')}
            items={courseUserIterested}
          />{' '}
          {/* todo request */}
          <CourseSlider sliderTitle={t('courses_most_viewed')} items={courseUserIterested} />{' '}
          {/* todo request */}
          {/* <UserHelpSearch /> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;

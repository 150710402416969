import StarImage from '../images/ReviewYellowStar.png';
import EmptyStarImage from '../images/RatingEmptyStar.png';
import 'skeleton-screen-css';
import Styles from './func.module.scss';

export function RenderStarsDependOnCourseRating(courseRating, starSize) {
  const starStyle = { width: `${starSize}px`, height: `${starSize}px` };
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < courseRating) {
      stars.push(<img key={i} src={StarImage} alt='Star' style={starStyle} />);
    } else {
      stars.push(<img key={i} src={EmptyStarImage} alt='Empty Star' style={starStyle} />);
    }
  }

  return stars;
}

export function PrettyMinutesFormater(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (minutes < 60) return `${remainingMinutes}m`;

  return `${hours}h ${remainingMinutes}m`;
}

export function PrettyDateFormater(isoString) {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

export function RegistrationFormValidateUserData(first_name, last_name, email, password) {
  if (!first_name || !last_name || !email || !password) {
    // throw new Error('User name and authentication string are required.');
    // console.log('-1');
  }

  return {
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
  };
}

export function LoginFormValidateUserData(email, password) {
  if (!email || !password) {
    throw new Error('--');
  }

  return {
    email: email,
    password: password,
  };
}

export function CheckPasswordOnStrenght(strength) {
  switch (strength) {
    case 'Weak':
      return 'red';
    case 'Medium':
      return 'orange';
    case 'Strong':
      return 'green';
    default:
      return 'black';
  }
}

export function BuyPanelSkillsLoadingAnimation() {
  return (
    <div className={Styles.BuyPanelSkillsLoadingAnimation}>
      <div class='ssc-line'></div>
      <div class='ssc-line'></div>
      <div class='ssc-line'></div>
      <div class='ssc-line'></div>
      <div class='ssc-line'></div>
      <div class='ssc-line'></div>
    </div>
  );
}

export const checkAuth = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  } else {
    return false;
  }
};

export function handleUserLogOut() {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  // setIsAuthenticated(false);   // Reset the authentication state in your app
  window.location.href = '/';
}

export function RenderCourseDirectionBlockChain() {
  return (
    <>
      <div className={Styles.ChainContainer}>
        <div className={Styles.ChainBox}>Development</div>
        <div className={Styles.ChainBox}>Web Development</div>
        <div className={Styles.ChainBox}>Python</div>
      </div>
    </>
  );
}

const StringMaxLenghtForCourseDesription = 200;

const TruncateCourseDescriprion = (Desc) => {
  if (Desc.length <= StringMaxLenghtForCourseDesription) {
    return Desc;
  } else {
    return Desc.slice(0, StringMaxLenghtForCourseDesription) + '...';
  }
};

const StringMaxLenghtForCourseName = 100;
const TruncateCourseName = (Name) => {
  if (Name.length <= StringMaxLenghtForCourseName) {
    return Name;
  } else {
    return Name.slice(0, StringMaxLenghtForCourseName) + '...';
  }
};

export function CoursePreviewDescriptionResponsive({ Description }) {
  return <p>{TruncateCourseDescriprion(Description)}</p>;
}

export function CoursePreviewNameResponsive({ Name }) {
  return <h1>{TruncateCourseName(Name)}</h1>;
}

{
  /* 1 HOUR =  VALUE 100 */
}
export function convertCourseTimeFormat(timeFloat) {
  if (typeof timeFloat !== 'number') {
    throw new Error('Invalid input type. Please provide a number.');
  }

  // Separate the float into minutes and fractional minutes
  const minutes = Math.floor(timeFloat);
  const fractionalMinutes = timeFloat - minutes;

  // Convert fractional minutes to seconds (0.5 * 60 = 30 seconds, etc.)
  const seconds = Math.round(fractionalMinutes * 60);

  // Format minutes and seconds to always have two digits
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  // Combine the formatted parts
  return `${formattedMinutes}:${formattedSeconds}`;
}

export function ValidateSubCategoryExist({ Category, SubCategory }) {
  if (SubCategory === undefined) {
    return <h1> {Category.charAt(0).toUpperCase() + Category.slice(1)} Courses</h1>;
  } else {
    return (
      <h1>
        {Category.charAt(0).toUpperCase() + Category.slice(1)},{' '}
        {SubCategory.charAt(0).toUpperCase() + SubCategory.slice(1)} Courses
      </h1>
    );
  }
}

const DescriptionMaxLenght = 100;

export const TruncateName = (description) => {
  if (description.length <= DescriptionMaxLenght) {
    return description;
  } else {
    return description.slice(0, DescriptionMaxLenght) + '...';
  }
};

export function formatNumberWithCommas(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

// function LoadCourseAnimation() {
//   return (
//     <div className={Styles.AnimationItem}>
//       <div className={Styles.HeadLineAnimation}>
//         <div className={Styles.SkeletonContentAnimation}>
//           <div className='ssc-square' style={{ height: '50px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//         </div>
//         <div className={Styles.SkeletonContentAnimation}>
//           <div className='ssc-square' style={{ height: '50px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//           <div class='ssc-head-line' style={{ width: '90%', marginTop: '5px' }}></div>
//         </div>
//       </div>
//     </div>
//   );
// }

import { Carousel } from 'antd';
import Styles from './NewsCarousel.module.scss';
import BackgroundPNG from '../../common/images/alina_preview3.jpg';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

function News({ Title, SubTitle, RedirectButton, Background }) {
  const button_exist = RedirectButton === '' ? false : true;

  return (
    <div className={Styles.News}>
      <img src={BackgroundPNG} alt='Background' className={Styles.BackgroundImage} />
      <div className={Styles.NewsTextWrapper}>
        <h4>{SubTitle}</h4>
        <h1>{Title}</h1>
      </div>
    </div>
  );
}

export default function NewsCarousel() {
  const { t, i18n } = useTranslation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    i18n.on('languageChanged', () => {
      setIsReady(true);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  return (
    <div className={Styles.Root}>
      <Carousel arrows infinite={true}>
        <News SubTitle={t('welcome_message')} Title={t('news_first_page')} />
        {/* <News
          SubTitle='More knowledge!'
          Title='The first 500 customers will receive a 50% coupon on any course!'
        />
        <News SubTitle='!!' Title='We looking for new ., create your own course for free!' /> */}
      </Carousel>
    </div>
  );
}

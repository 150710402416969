import { Drawer } from 'antd';
import Styles from './HeaderDrawler.module.scss';
import Inst from '../../common/images/instagramIcon.png';

function SocialLinks() {
  return (
    <div>
      <a href='/'>
        <img src={Inst} />
      </a>
    </div>
  );
}

function DrawerLinks() {
  return (
    <>
      <a href='/'>Home</a>
    </>
  );
}

function DrawerBottom() {
  return (
    <div className={Styles.DrawerBottomRoot}>
      <hr />
      <h1 className={Styles.BottomTitle}>Follow us in social:</h1>
      <SocialLinks />
    </div>
  );
}

export default function HeaderDrawer({ open, onClose }) {
  return (
    <>
      <Drawer title='Ednely' onClose={onClose} open={open}>
        <div className={Styles.Root}>
          <DrawerLinks />
          <DrawerBottom />
        </div>
      </Drawer>
    </>
  );
}

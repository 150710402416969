import React from 'react';
import Styles from './Review.module.scss';
import Slider from '../Sliders/ReviewSlider';

// todo request this review in slider before render

const Reviews = [
  // {
  //   reviewAuthorImage:
  //     'https://pbs.twimg.com/profile_images/1674815862879178752/nTGMV1Eo_400x400.jpg',
  //   reviewAuthorName: 'Bill Gates',
  //   reviewAuthorOccupation: 'CEO of Google',
  //   reviewAuthorText: `The Internet is going to be a big deal in education. It already is, but it's going to be even more so.`,
  // },
  // {
  //   reviewAuthorImage:
  //     'https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fd1e00ek4ebabms.cloudfront.net%2Fproduction%2F0ce44c66-2df4-4dbd-9c05-4e6963ec6726.jpg?source=next-article&fit=scale-down&quality=highest&width=700&dpr=1',
  //   reviewAuthorName: 'Elon Musk',
  //   reviewAuthorOccupation: 'CEO of SpaceX & Tesla',
  //   reviewAuthorText:
  //     'You can learn anything you want for free. It is not about learning the facts; it’s about figuring out how to get the facts you need to solve problems.',
  // },
  {
    reviewAuthorImage:
      'https://assets.weforum.org/sf_account/image/lTVjaGzCnvgDSfNDOMQGl_S0MIxqe0zD7yYEyaJhJ-M.jpg',
    reviewAuthorName: 'Sheryl Sandberg',
    reviewAuthorOccupation: 'COO of Facebook',
    reviewAuthorText:
      '"The power of digital tools to give people access to educational opportunities, even in remote areas, is transformative."',
  },
  {
    reviewAuthorImage:
      'https://m.media-amazon.com/images/M/MV5BM2U4Yjc5ZTQtZWIxYS00ODBkLWJmOWYtODI1NjdiOTFiYmE2XkEyXkFqcGdeQXVyMTMxMTIwMTE0._V1_.jpg',
    reviewAuthorName: 'Reed Hastings',
    reviewAuthorOccupation: 'CEO of Netflix',
    reviewAuthorText: `"The shift to online education is happening. The question is how quickly and how broadly it will happen. But it's clearly the direction we're going in."`,
  },
  {
    reviewAuthorImage:
      'https://im.indiatimes.in/content/2023/Oct/satya-nadella-emotional-intelligence_6527ccf9ba736.jpg?w=1200&h=900&cc=1&webp=1&q=75',
    reviewAuthorName: 'Satya Nadella',
    reviewAuthorOccupation: 'CEO of Microsoft',
    reviewAuthorText: `The future of education is all about making learning more personalized, accessible, and inclusive. Technology can help break down barriers and provide opportunities for lifelong learning.`,
  },
];

export default function GeneralReviews() {
  return (
    <>
      <div className={Styles.SliderWrapper}>
        <Slider items={Reviews} />
      </div>
    </>
  );
}

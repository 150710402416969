import React, { Component } from 'react';
import Header from '../../components/header/header';
import Styles from './business.module.scss';
import Footer from '../../components/Footer/Footer';
import axios from 'axios';

import IMAGE_PNG from './images/img.png';
function FormDesc() {
  return (
    <div className={Styles.FormDesc}>
      <div>
        <h1>
          Registration of an organization allows you to publish courses on behalf of the company
        </h1>
      </div>

      <ul>
        <li>
          <img src={IMAGE_PNG} /> Company status on our platform
        </li>
        <li>
          <img src={IMAGE_PNG} /> Company status on our platform
        </li>
        <li>
          <img src={IMAGE_PNG}></img> Company status on our platform
        </li>
        <li>
          <img src={IMAGE_PNG}></img> Company status on our platform
        </li>
        <li>
          <img src={IMAGE_PNG}></img> Company status on our platform
        </li>
      </ul>
    </div>
  );
}

class CreateOrganization extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      representative: '',
      mail: '',
      number: '',
      country: '',
      employees: 0, // under e.g 100
      city: '',
      website: '',
    };
  }

  handleInputChange = (e) => {
    // const { name, value } = e.target;
    // this.setState({ [name]: value });
  };
}

class Form extends Component {
  // let newOrganization = {
  //   name: "",
  //   representative: "",
  //   mail: "",
  //   number: "",
  //   country: "",
  //   employees: 0, // under e.g 100
  //   city: "",
  //   website: ""
  // };

  constructor() {
    super();
    this.state = {
      name: '',
      representative: '',
      mail: '',
      number: '',
      country: '',
      employees: 0, // under e.g 100
      city: '',
      website: '',
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { name, representative, mail, number, country, employees, city, website } = this.state;
    const newOrganization = {
      name,
      representative,
      mail,
      number,
      country,
      employees,
      city,
      website,
    };

    try {
      // Assuming axios is imported
      await axios.post('http://localhost:3002/organization/apply', newOrganization);
      console.log('new orga successfully');
    } catch (error) {
      console.error('Error organiz save:', error.message);
    }
  };

  render() {
    return (
      <div className={Styles.Form}>
        <div className={Styles.Form__Fill}>
          <div className={Styles.Form__area}>
            <form onSubmit={this.handleSubmit}>
              <div className={Styles.Form__input}>
                <input
                  placeholder='Establishment name (e.g Western National University)'
                  name='name'
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={Styles.Form__input}>
                <input
                  placeholder='Representative'
                  name='representative'
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={Styles.Form__input}>
                <input placeholder='Company Mail' name='mail' onChange={this.handleInputChange} />
              </div>
              <div className={Styles.Form__input}>
                <input
                  placeholder='Contact Number'
                  name='number'
                  onChange={this.handleInputChange}
                />
              </div>
              <div className={Styles.Form__input}>
                <input placeholder='Country' name='country' onChange={this.handleInputChange} />
              </div>
              <div className={Styles.Form__input}>
                <input placeholder='City' name='city' onChange={this.handleInputChange} />
              </div>
              <div className={Styles.Form__input}>
                <input placeholder='Website' name='website' onChange={this.handleInputChange} />
              </div>
            </form>
            <button type='submit'>SUBMIT</button>
            <h1>By registration corporation account you agree with us terms of us</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default function Businesss() {
  return (
    <div>
      <Header />
      <div className={Styles.Forms}>
        <FormDesc />
        <Form />
      </div>
      <Footer />
    </div>
  );
}

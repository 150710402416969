import React from 'react';
import Styles from './InformationSection.module.scss';
import Image from '../../common/images/IllustrateHomeBackground.png'; // TEST
import { useTranslation } from 'react-i18next';

export default function CourseCategories() {
  const { t, i18n } = useTranslation();

  // todo
  return (
    <div className={Styles.Info}>
      <div className={Styles.Info__content}>
        <div className={Styles.Info__block}>
          <div className={Styles.PNumber}>1</div>
          <h1>{t('text_more_free_time')}</h1>
        </div>
        <p>{t('sub_text_more_free_time')}</p>

        <div className={Styles.Info__block}>
          <div className={Styles.PNumber}>2</div>
          <h1>{t('text_wide_range_of_courses')}</h1>
        </div>
        <p>{t('sub_text_wide_range_of_courses')}</p>

        <div className={Styles.Info__block}>
          <div className={Styles.PNumber}>3</div>
          <h1>{t('text_cost_savings')}</h1>
        </div>
        <p>{t('sub_text_cost_savings')}</p>
      </div>

      {/* Change on animation, maybe..*/}
      <div className={Styles.Info__graphic}>
        <img src={Image}></img>
      </div>
    </div>
  );
}

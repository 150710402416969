import Styles from './HeaderCartMenu.module.scss';
import { useState, useEffect } from 'react';
import ShopingCartImage from '../../common/images/HeaderShopingCart.png';
import { Link } from 'react-router-dom';
// import { requestCourseCart } from '../../../../common/Services/Services';
import { RenderStarsDependOnCourseRating } from '../../common/func/func';
import { requestUserCartCourses } from '../../common/Services/UserService';

const ShopingCartIsEmpty = () => {
  return (
    <div className={Styles.ShopingCartEmpty}>
      <h1>Shoping cart is empty yet</h1>
    </div>
  );
};

function Course(course) {
  const { courseName, CoursePrice } = course;
  const coursePreview =
    'https://prod-printler-front-as.azurewebsites.net/media/photo/137968.jpg?mode=crop&width=727&height=1024&rnd=0.0.1';

  return (
    <>
      <div className={Styles.Course}>
        <img className={Styles.CoursePreview} src={coursePreview} />
        <div className={Styles.CourseDataWrapper}>
          <h1 className={Styles.CourseName}>Rust for backend developers</h1>
          <h2 className={Styles.CourseRating}>{RenderStarsDependOnCourseRating(3.2, 15)}</h2>
        </div>
      </div>
    </>
  );
}

function CourseCartItems() {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // let res = requestUserCartCourses();
    // setCartItems(res);
  });

  return (
    <>
      <div className={Styles.CartItemsRoot}>
        <Course />
        <Course />
      </div>

      <div className={Styles.CartCheckout}>
        <div className={Styles.TotalPriceWrapper}>
          <h1 className={Styles.CartTotalPriceTitle}>Total Price</h1>
          <div className={Styles.DiscountWrapper}>
            <h1 className={Styles.CartTotalPriceTitle}>$9.99</h1>
            <s className={Styles.CartTotalPriceTitle}>$129.99</s>
          </div>
        </div>
        <hr />
        <Link to='/cart/checkout'>
          <button>Checkout $9.99</button>
        </Link>
      </div>
    </>
  );
}

const DropdownMenu = () => {
  const [shopingCartItemsAmount, setShopingCartItemsAmount] = useState(0);

  return (
    <div className={Styles.dropdownMenu}>
      {shopingCartItemsAmount === 0 ? <ShopingCartIsEmpty /> : <CourseCartItems />}
    </div>
  );
};

export default function CartMenu() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <>
      <div className={Styles.Root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Link to='/cart/checkout'>
          <img className={Styles.HeaderCartImage} src={ShopingCartImage}></img>
        </Link>
        {isDropdownVisible && <DropdownMenu />}
      </div>
    </>
  );
}

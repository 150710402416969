import { Drawer } from 'antd';
import Styles from './CompanyDrawer.module.scss';
import Inst from '../../common/images/instagramIcon.png';
import Youtube from '../../common/images/youtubeIcon.png';
import Facebook from '../../common/images/facebookIcon.png';
import Twitter from '../../common/images/twitterIcon.png';
import LinkedIn from '../../common/images/LinkedinIcon.png';

function SocialLinks() {
  return (
    <div className={Styles.LinksRoot}>
      <a href='/' className={Styles.Link}>
        <img src={Inst} />
      </a>
      <a href='/' className={Styles.Link}>
        <img src={Youtube} />
      </a>
      <a href='/' className={Styles.Link}>
        <img src={Facebook} />
      </a>
      <a href='/' className={Styles.Link}>
        <img src={Twitter} />
      </a>
      <a href='/' className={Styles.Link}>
        <img src={LinkedIn} />
      </a>
    </div>
  );
}
function DrawerLinks() {
  return (
    <>
      <a href='/'>Home</a>
      <a href='/about'>About us</a>
      <a href='/news'>News</a>
      <a href='/blog'>Blog</a>
      <a href='/contact'>Contact us</a>
      <a href='/career'>Career</a>
      <a href='/partners'>Partners</a>
    </>
  );
}

function DrawerBottom() {
  return (
    <div className={Styles.DrawerBottomRoot}>
      <hr />
      <h1 className={Styles.BottomTitle}>Follow us in social:</h1>
      <SocialLinks />
    </div>
  );
}

export default function CompanyDrawer({ open, onClose }) {
  return (
    <>
      <Drawer title='Ednely' onClose={onClose} open={open}>
        <div className={Styles.Root}>
          <DrawerLinks />
          <DrawerBottom />
        </div>
      </Drawer>
    </>
  );
}

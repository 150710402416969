import Styles from './AboutUs.module.scss';
import Header from '../../components/AboutCompanyHeader/AboutCompanyHeader';
import Footer from '../../components/Footer/Footer';
import CEO_IMAGE from '../../common/images/AboutUsCEOimage.jpeg';
import { useEffect } from 'react';
import World from '../../common/images/IllustrateWorld.svg';
import Sharing from '../../common/images/illustrateShharing.svg';
import Browsing from '../../common/images/IllustrateBrowsing.svg';

import CEO from './temp1.jpg';
function RenderCeo() {
  return (
    <div className={Styles.CeoRoot}>
      <img className={Styles.CeoImage} src={CEO} />
      <div className={Styles.CeoAboutWrapp}>
        <h1>Svyatoslav Vermiy</h1>
        <h2>Founder & CEO</h2>
      </div>
    </div>
  );
}
function RenderTotalMessage() {
  const HeaderMessage =
    'The constant growth of technology increases the need for new knowledge and skills, driving individuals and industries to continuously adapt and innovate to stay competitive in a rapidly evolving landscape.';
  const OurGoal =
    'Our mission is to keep pace with the constant growth of technology, fostering the acquisition of new knowledge and skills to ensure continuous adaptation and innovation in a rapidly evolving  environment.';
  const AboutEdnely = `Ednely is a young marketplace, since our establishment, we have been committed to creating optimal conditions for both students and teachers, cultivating a supportive and innovative environment for learning and teaching.`;
  const JoinUs = `Join us and explore how we can help you!`;
  const Way = `It was an fascinating experience for both me and my team. Step by step we tried to improve our platform to make it as convenient as possible for every user. `;
  const Education = `It is safe to say that social networks and the Internet in general are part of our lives, moving with this idea, we also have to agree to get new knowledge online has become much more effective, perhaps even better than traditional learning.`;
  const Idea = `So it makes me happy to know that we can promote this idea and be the ones that can change the lives of many people.`;

  return (
    <div className={Styles.MessageRoot}>
      <h1>{HeaderMessage}</h1>
      <h1>{OurGoal}</h1>
      <h1>{AboutEdnely}</h1>
      <h1>{Way}</h1>
      <h1>{Education}</h1>
      <h1>{Idea}</h1>
      <h1>{JoinUs}</h1>
    </div>
  );
}

function Buttons() {
  return (
    <div className={Styles.ButtonsRoot}>
      <a href='/partners'>
        <button>Become our Partners</button>
      </a>
      <a href='/catalog/all'>
        <button>Find course</button>
      </a>
      <a href='/mentor'>
        <button>Become mentor</button>
      </a>
    </div>
  );
}

export default function About() {
  useEffect(() => {
    document.title = 'About | Ednely';
  });

  return (
    <>
      <Header />
      <div className={Styles.Root}>
        <h1 className={Styles.AboutUsTitle}>About us</h1>
        <RenderCeo />
        <RenderTotalMessage />
        <Buttons />
      </div>
      <Footer />
    </>
  );
}

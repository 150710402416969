import React from 'react';
import Styles from './Footer.module.scss';
import Logo from '../../common/images/FooterLogoBlackWhite.png';
import { Link } from 'react-router-dom';

import InstagramLogo from '../../common/images/FooterInstagramIcon.png';
import TwitterLogo from '../../common/images/icons8-x-50.png';
import FacebookLogo from '../../common/images/FooterFacebookIcon.png';
import YoutubeLogo from '../../common/images/FooterYoutubeIcon.png';
import LinkedINLogo from '../../common/images/icons8-linkedin-50.png';
import LanguageModal from '../header/components/UserMenu/LanguagesModal/LanguageModal';
import { useTranslation } from 'react-i18next';

function RenderSocialMediaLinks() {
  return (
    <div className={Styles.SocialRoot}>
      <div className={Styles.Link}>
        <Link to='https://instagram.com/ednely.me'>
          <img src={InstagramLogo} alt='Instagram Logo' />
        </Link>
      </div>
      <div className={Styles.Link}>
        <Link to='https://instagram.com/ednely.me'>
          <img src={TwitterLogo} alt='Instagram Logo' />
        </Link>
      </div>
      <div className={Styles.Link}>
        <Link to='https://instagram.com/ednely.me'>
          <img src={FacebookLogo} alt='Instagram Logo' />
        </Link>
      </div>
      <div className={Styles.Link}>
        <Link to='https://www.youtube.com/@Ednely'>
          <img src={YoutubeLogo} alt='Youtube Logo' />
        </Link>
      </div>
      <div className={Styles.Link}>
        <Link to='https://www.linkedin.com/company/ednely/'>
          <img src={LinkedINLogo} alt='LinkedIn Logo' />
        </Link>
      </div>
      <LanguageModal />
    </div>
  );
}

function FooterBottom() {
  return (
    <div className={Styles.FooterBottom}>
      <hr />
      <div className={Styles.BottomWrapper}>
        <img className={Styles.FooterLogo} src={Logo}></img>
        <RenderSocialMediaLinks />
      </div>
    </div>
  );
}

function Columns() {
  const { t, i18n } = useTranslation();

  return (
    <div className={Styles.FooterNavigationLinks}>
      <Link to='/'>{t('footer_home')}</Link>
      <Link to='/about'>{t('footer_about_us')}</Link>
      <Link to='/coockie-settings'>Cookie Settings</Link>
      <Link to='/contact'>{t('footer_contact_us')}</Link>
      <Link to='/news'>{t('footer_news')}</Link>
      <Link to='/blog'>{t('footer_blog')}</Link>
      <Link to='/mentor'>{t('footer_tech_on_ednely')}</Link>
      <Link to='/partners'>{t('footer_partnership')}</Link>
      <Link to='/hc'>{t('footer_help_support')}</Link>
      <Link to='/career'>{t('footer_career')}</Link>
      <Link to='/privacy-policy'>{t('footer_privacy_policy')}</Link>
      <Link to='/sitemap'>{t('footer_sitemap')}</Link>
      <Link to='/terms'>{t('footer_terms')}</Link>
    </div>
  );
}

export default function Footer() {
  return (
    <div className={Styles.Root}>
      <div className={Styles.Footer}>
        <Columns />
      </div>
      <FooterBottom />
    </div>
  );
}

import 'skeleton-screen-css';
import Styles from './LoadingAnimation.module.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export function BigSliderLoadingAnimation() {
  let loading = true;

  return (
    <div className={Styles.BigSliderRoot}>
      <div className=''>{loading ? <Skeleton width={600} height={450} /> : 'John Doe'}</div>
      <div className={Styles.BigSliderWrapContext}>
        <div className='user-name'>
          {loading ? <Skeleton width={700} height={50} /> : 'John Doe'}
        </div>
        <div className={Styles.Line}>{loading ? <Skeleton width={400} /> : 'John Doe'}</div>
        <div className={Styles.Line}>{loading ? <Skeleton width={400} /> : 'John Doe'}</div>
        <div className={Styles.Line}>{loading ? <Skeleton width={400} /> : 'John Doe'}</div>
      </div>
    </div>
  );
}

export function CourseSliderLoadingAnimation() {
  let loading = true;

  return (
    <div className={Styles.CourseRoot}>
      <div className='user-name'>
        {loading ? <Skeleton width={300} height={150} /> : 'John Doe'}
      </div>
      <div className={Styles.CourseLine}>
        {loading ? <Skeleton width={300} height={20} /> : 'John Doe'}
      </div>
      <div className={Styles.SmallLine}>
        {loading ? <Skeleton width={230} height={10} /> : 'John Doe'}
      </div>
      <div className={Styles.SmallLine}>
        {loading ? <Skeleton width={230} height={10} /> : 'John Doe'}
      </div>
    </div>
  );
}

export function BlogPostLoading() {
  let loading = true;

  return (
    <div className={Styles.CourseRoot}>
      <div className='user-name'>
        {loading ? <Skeleton width={300} height={150} /> : 'John Doe'}
      </div>
      <div className={Styles.CourseLine}>
        {loading ? <Skeleton width={300} height={20} /> : 'John Doe'}
      </div>
      <div className={Styles.SmallLine}>
        {loading ? <Skeleton width={230} height={10} /> : 'John Doe'}
      </div>
      <div className={Styles.SmallLine}>
        {loading ? <Skeleton width={230} height={10} /> : 'John Doe'}
      </div>
    </div>
  );
}

export function CourseAboutLoadingAnimation() {
  return <div></div>;
}

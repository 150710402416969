import axios from 'axios';
import WistiaPlayer from 'react-player/wistia';

// TODO
/// > ACTUALY ALL FUNCTION OF REQUEST WITH TRY BLOCK NEED EXECUTE HERE
//

const instance = axios.create({
  baseURL: 'http://localhost:3000/',
});

export async function requestSpecificCourseByName({ courseName }) {
  const endpoint = `http://localhost:3002/course/${courseName}`;
  const response = await axios.get(endpoint);

  // console.log('requestSpecificCourseByName: ', response.data);
  return response.data;
}

export async function requestCoursesSectionMightBeInterested() {
  const endpoint = 'http://localhost:3002/api/courses/interested';
  const response = await axios.get(endpoint);

  // console.log('requestCoursesSectionMightBeInterested: ', response.data);
  return response.data;
}

export async function requestCoursesSectionMostPopular() {
  const endpoint = 'http://localhost:3002/api/courses/interested'; // TODO
  const response = await axios.get(endpoint);

  // console.log('requestCoursesSectionMostPopular: ', response.data);
  return response.data;
}

export async function requestCoursesSectionNew() {
  const endpoint = 'http://localhost:3002/api/courses/interested'; // TODO
  const response = await axios.get(endpoint);

  // console.log('requestCoursesSectionNew: ', response.data);
  return response.data;
}

export async function requestCoursesModules({ courseName }) {
  const endpoint = `http://localhost:3002/api/courses/${courseName}/modules`;
  const response = await axios.get(endpoint);

  // console.log('requestCoursesModules: ', response.data);
  return response.data;
}

export async function requestCourses() {}

export async function requestCourseCart() {
  try {
    const token = localStorage.getItem('jwtToken');

    const response = await axios.get('http://localhost:3002/api/users/cart', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function requestCourseGainSkills() {
  const endpoint = 'http://localhost:3002/api/course/skills';
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching course skills:', error);
    throw error;
  }
}

export async function requsetCourseData() {
  const endpoint = 'http://localhost:3002/api/course/data/';

  try {
    const response = await axios.get(endpoint);
    return response.data;
    console.log(response.data);
  } catch (error) {
    console.error('Error fetching course skills:', error);
    throw error;
  }
}

export async function requsetCourseNiceToHave() {
  const endpoint = 'http://localhost:3002/api/course/musthave';

  try {
    const response = await axios.get(endpoint);
    return response.data;
    console.log(response.data);
  } catch (error) {
    console.error('Error fetching course skills:', error);
    throw error;
  }
}

export async function AddCurrentCourseToCart() {
  // const endpoint = 'http://localhost:3002/home/courses/interested';
  // const response = await axios.post(endpoint);
  // console.log('requestCoursesSectionMostPopular: ', response.data);
  // return response.data;
}

export async function requestNotifications() {
  try {
    const response = await instance.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function requestUserEnrollmentsCourses() {
  try {
    const response = await axios.get('http://localhost:3002/api/courses/enrollments');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function requestUserHeaderData() {
  const token = localStorage.getItem('token');
  if (!token) {
    // console.log('No token found, user is not authenticated.');
    return;
  }

  try {
    const response = await axios.get('/api/users/data/user', {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === 200 && response.data.success) {
      console.log('User data:', response.data.user);
      return response.data;
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function requestHomeReivews() {}

export async function SendEmailService(user) {
  const { userName, userLastName, userEmail, userMessage } = user;

  const endpoint = 'http://localhost:3002/api/send-email/';

  // try {
  //   const response = await axios.post(endpoint, validatedUser, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   if (response.status === 200) {
  //     // console.log('> AUTH TOKEN', response.data.token);
  //     localStorage.setItem('token', response.data.token);
  //     window.location.href = '/home/';
  //   }
  // } catch (error) {
  //   console.error('Error:', error);
  //   throw error;
  // }
}

export async function requestCreateNewUser(validatedUser) {
  const endpoint = 'http://localhost:3002/api/users/new';

  console.log('validates user:', validatedUser);

  try {
    const response = await axios.post(endpoint, validatedUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      // console.log('> AUTH TOKEN', response.data.token);
      localStorage.setItem('token', response.data.token);
      window.location.href = '/home/';
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function requestBlogs() {}

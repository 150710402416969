import Styles from './HeaderSearchBar.module.scss';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import pngS from '../../common/images/HeaderInputSearchIcon.png';

// const renderTitle = (title) => (
//   <span>
//     {title}
//     <a
//       style={{
//         float: 'right',
//       }}
//       href='https://www.google.com/search?q=antd'
//       target='_blank'
//       rel='noopener noreferrer'
//     >
//       more
//     </a>
//   </span>
// );
// const renderItem = (title, count) => ({
//   value: title,
//   label: (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//       }}
//     >
//       {title}
//       <span>
//         <UserOutlined /> {count}
//       </span>
//     </div>
//   ),
// });
// const options = [
//   {
//     label: renderTitle('Development'),
//     options: [renderItem('Rust for beginners', 10000), renderItem('AntDesign UI', 10600)],
//   },
//   {
//     label: renderTitle('Business'),
//     options: [renderItem('AntDesign UI FAQ', 60100), renderItem('AntDesign FAQ', 30010)],
//   },
//   {
//     label: renderTitle('Design'),
//     options: [renderItem('AntDesign design language', 100000)],
//   },
// ];

function SearchBar({ placeholder }) {
  return (
    <div className={Styles.SearchBarRoot}>
      <Input className={Styles.InputTodo} placeholder={placeholder} />
      <a href='/catalog/all'>
        <Button className={Styles.ButtonTodo}>
          <img src={pngS}></img>
        </Button>
      </a>
    </div>
  );
}

export default function HeaderSearchInput() {
  const { t } = useTranslation();

  return (
    <div className={Styles.SearchInput}>
      <SearchBar placeholder={t('header_search_input_placeholder')} />

      {/* <AutoComplete
        className={Styles.CompleteSearchInput}
        popupClassName='certain-category-search-dropdown'
        popupMatchSelectWidth={'100%'}
        style={{
          width: '100%',
          height: '50px',
        }}
        options={options}
        size='large'
      >
        <input
          className={Styles.ValueInput}
          size='large'
          placeholder={t('header_search_input_placeholder')}
        />
      </AutoComplete> */}
    </div>
  );
}

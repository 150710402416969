import React, { useState, useEffect } from 'react';
import Styles from './header.module.scss';
import { Link } from 'react-router-dom';

import CoursesMenu from './CoursesMenu';
import CartMenu from './HeaderCartMenu';
import NotificationMenu from './HeaderNotifications';
import IllSearch from '../../common/images/HeaderInputSearchIcon.png';

import HeaderSearchInput from './HeaderSearchBar';
import UserHeaderContent from './components/UserMenu/UserMenu';
import { checkAuth } from '../../common/func/func';
import Logo from '../../common/images/LogoBlack.png';
import { useTranslation } from 'react-i18next';

import HambPng from '../../common/images/hamburger.png';
import InputPng from '../../common/images/InputSearchIcon.png';
import HeaderDrawer from './HeaderDrawler';

function RenderHeaderLinks() {
  const { t } = useTranslation();

  return (
    <div className={Styles.HeaderLinks}>
      <CoursesMenu />
      {/* <Link to='/mentor' className={Styles.MentorLink}>
        {t('header_mentor')}
      </Link> */}
      {/* <Link to='/business' className={Styles.BusinessLink}>
        {t('header_business')}
      </Link> */}
    </div>
  );
}

function NotAuthenticationUser() {
  const { t } = useTranslation();

  return (
    <div className={Styles.AuthButtons}>
      <Link to='/login'>
        <button className={Styles.HeaderLoginButton}> {t('button_login')}</button>
      </Link>
      <Link to='/register'>
        <button className={Styles.HeaderSignUpButton}>{t('button_sign_up')}</button>
      </Link>
    </div>
  );
}

function SearchButton() {
  return (
    <div className={Styles.SearchButtonRoot}>
      <button className={Styles.SearchButton}>
        <img src={IllSearch} />
      </button>
    </div>
  );
}

function AuthenticationUser() {
  return (
    <div className={Styles.AuthenticationUser}>
      <SearchButton />
      <CartMenu />
      <NotificationMenu />
      <UserHeaderContent />
    </div>
  );
}

function RenderUserDependsOnAuth({ isAuthenticated }) {
  return <>{isAuthenticated ? <AuthenticationUser /> : <NotAuthenticationUser />}</>;
}

function HeaderLogoElement() {
  return (
    <Link to='/'>
      <img src={Logo} alt='Logo' className={Styles.logo} />
    </Link>
  );
}

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkUserAuth = async () => {
      const authStatus = await checkAuth();
      setIsAuthenticated(authStatus);
    };

    checkUserAuth();

    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 600);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderLargeScreenMenu = () => (
    <>
      <RenderHeaderLinks />
      <HeaderSearchInput />
      <RenderUserDependsOnAuth isAuthenticated={isAuthenticated} />
    </>
  );

  return (
    <div className={Styles.Root}>
      <HeaderLogoElement />
      <HeaderDrawer />
      <button className={Styles.OpenSearchBarButton}>
        <img src={InputPng} />
      </button>
      <button className={Styles.OpenDrawerButton} onClick={showDrawer}>
        <img src={HambPng} />
      </button>
      {!isSmallScreen && renderLargeScreenMenu()}
    </div>
  );
};

export default Header;

import Styles from './Forms.module.scss';
import React, { useState } from 'react';
import { Input, Space } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export function RegistrationEmailForm({ setEmail }) {
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  const handleSearch = (value) => {
    setOptions(() => {
      if (!value || value.includes('@')) {
        return [];
      }
      return ['gmail.com', 'yahoo.com', 'outlook.com'].map((domain) => ({
        label: `${value}@${domain}`,
        value: `${value}@${domain}`,
      }));
    });
  };

  const handleChange = (value) => {
    setEmail(value);
  };

  return (
    // <AutoComplete
    <Input
      className={Styles.EmailInputForm}
      onSearch={handleSearch}
      onChange={handleChange}
      placeholder={t('text_email')}
      options={options}
    />
  );
}

export function RegistrationPasswordForm({ setPassword }) {
  const { t } = useTranslation();

  return (
    <div className={Styles.MenuInput}>
      <Space direction='vertical'>
        <Input.Password
          className={Styles.PasswordInputForm}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('text_password')}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Space>
    </div>
  );
}

function validateTwoWords(input) {
  const trimmedInput = input.trim();
  const words = trimmedInput.split(/\s+/);

  if (words.length === 2) {
    return true;
  } else {
    return false;
  }
}

export function RegistrationUserNameForm({ setUsername }) {
  const { t } = useTranslation();

  return (
    <div className={Styles.MenuInput}>
      <Space direction='vertical'>
        <Input
          className={Styles.UserInputForm}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={t('text_name_surname')}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Space>
    </div>
  );
}

export function CustomForm({ Title, DataForSet, Value, width, height }) {
  const { t } = useTranslation();

  return (
    <div className={Styles.CustomMenuInput} style={{ width, height }}>
      <Space direction='vertical'>
        <h1 className={Styles.CustomFormTitle}>{Title}</h1>
        <Input
          value={Value}
          style={{ width }} // Set the width here
          className={Styles.CustomInputForm}
          onChange={(e) => DataForSet(e.target.value)}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Space>
    </div>
  );
}

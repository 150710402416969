import { HashRouter, BrowserRouter, Routes, Route } from 'react-router-dom';


import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Registration from './pages/UserRegistrationForm/UserRegistrationForm';
import Home from './pages/Home/Home';
import About from './pages/AboutCompany/AboutUs';
import Contact from './pages/AboutCompany/Contact';
import Authentication from './pages/Authentication/Login';
import Course from './pages/Course/CoursePreview';
import Career from './pages/AboutCompany/Career';
import CourseContent from './pages/CourseContent/CoursePlayer';
import Businesss from './pages/business/business';
import Terms from './pages/Terms/Terms';
import Tool from './pages/Tools/tools';
import Catalog from './pages/CoursesCatalog/CoursesCatalog';
import Content from './pages/Course-creator/Content/content';
import axios from 'axios';
import NotFound from './pages/CommonPages/CourseNotFound';
import InstructorApply from './pages/InstructorRegistrationForm/InstructorRegistrationForm';
import News from './pages/AboutCompany/News';
import CompanyPartners from './pages/Partners/CompanyPartners';
import Checkout from './pages/Checkout/Checkout';
import SiteMap from './pages/Sitemap/Sitemap';
import Mentor from './pages/Mentor/Mentor';
import Blog from './pages/AboutCompany/Blog';
import PrivacyPolicy from './pages/AboutCompany/PrivacyPolicy';

// ==== ROLES OFFICE PAGES  ====
import User from './pages/roles/user/user';
import Moderator from './pages/roles/moderator/moderator';
import Admin from './pages/roles/admin/administrator';
import Instructor from './pages/roles/instructor/instructor';
import InstructorPage from './pages/roles/instructor/page/instructorPage';
import Organization from './pages/roles/organization/organization';

// ==== COURSE CREATOR TOOLS ======
import CourseGeneral from './pages/Course-creator/pages/general/general';
import CourseTopics from './pages/Course-creator/pages/topics/topics';
import CourseRequirements from './pages/Course-creator/pages/requirements/requirements';
import CourseSubmit from './pages/Course-creator/pages/submit/submit';

import ForgotPassword from './pages/ForgotPassword/ResetPassword';
import Office from './pages/office/office';

import Enrollments from './pages/roles/user/UserAccountTabs/Enrollments';
import WishList from './pages/roles/user/UserAccountTabs/wishlist';
import HelpCenter from './pages/HelpHenter/HelpCenter';
import NotificationsTab from './pages/roles/user/UserAccountTabs/Notifications';
import AnnouncementsTab from './pages/roles/user/UserAccountTabs/Announcements';
import GuestUserView from './pages/GuestUserView/GuestUserView';

// === <> ===
import PageForbid from './pages/CommonPages/Forbid';
import PageAccessDenied from './pages/CommonPages/Denied';
import PageNotFound from './pages/CommonPages/PageNotFound';
import Account from './pages/roles/user/UserAccountTabs/Account';
import { checkAuth } from './common/func/func';

axios.defaults.baseURL = 'http://localhost:5000';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkUserAuth = async () => {
      const authStatus = await checkAuth();
      setIsAuthenticated(authStatus);
    };

    checkUserAuth();
  }, []);

  // return (
  //   <div className='App'>
  //     <BrowserRouter>
  //       <Routes>
  //         {/* ============== USERS =============== */}
  //         <Route path='/account' element={<Account />} />
  //         <Route path='/enrollments' element={<Enrollments />} />
  //         <Route path='/wishList' element={<WishList />} />
  //         <Route path='/notifications' element={<NotificationsTab />} />
  //         <Route path='/announcements' element={<AnnouncementsTab />} />
  //         <Route path='/users/:name' element={<GuestUserView />} />

  //         {/* ============== INSTRUCTOR =============== */}
  //         <Route path='/Mentor' element={<Mentor />} />

  //         {/* This one with dashboard */}
  //         {/* <Route path='/instructor/:name' element={<InstructorPage />} /> */}
  //         <Route path='/instructor/:name' element={<Instructor />} />

  //         {/* ============== COURSES =============== */}

  //         {/* ============== STATUS CODE =============== */}
  //         <Route path='/support' element={<HelpCenter />} />
  //         <Route path='/forbid' element={<PageForbid />} />
  //         <Route path='/denied' element={<PageAccessDenied />} />
  //         <Route path='/*' element={<PageNotFound />} />

  //         <Route index element={<Home />} />
  //         <Route path='/api/video' element={<CourseContent />} />
  //         <Route path='/course/:name' element={<Course />} />
  //         <Route path='/course-not-found' element={<NotFound />} />
  //         <Route path='/contact' element={<Contact />} />
  //         <Route path='/organization/apply' element={<Businesss />} />
  //         <Route path='/Career' element={<Career />} />

  //         <Route path='/sitemap' element={<SiteMap />} />
  //         <Route path='/mentor/apply' element={<InstructorApply />} />

  //         <Route path='/about' element={<About />} />
  //         <Route path='/news' element={<News />} />
  //         <Route path='/partners' element={<CompanyPartners />} />
  //         <Route path='/blog' element={<Blog />} />
  //         <Route path='/login' element={<Authentication />} />
  //         <Route path='/register' element={<Registration />} />
  //         <Route path='/forgot-password' element={<ForgotPassword />} />

  //         <Route path='/terms' element={<Terms />} />
  //         <Route path='/courses/:name' element={<Catalog />} />
  //         <Route path='/courses/:name/:subcategory' element={<Catalog />} />

  //         <Route path='/user/:name' element={<User />} />

  //         <Route path='/moderator/:name' element={<Moderator />} />
  //         <Route path='/admin/:name' element={<Admin />} />

  //         <Route path='/organization/:name' element={<Organization />} />

  //         <Route path='/home/' element={<Office />} />

  //         {/* <Route path="/instructor/dashboard" element={<Dashboard />} /> */}

  //         <Route path='/course/create' element={<Tool />} />
  //         <Route path='/course/create/content' element={<Content />} />
  //         <Route path='/course/create/submit' element={<CourseSubmit />} />

  //         {/* Checkout */}
  //         <Route path='/cart/checkout/' element={<Checkout />} />

  //         {/* IDK, Navigate i guess, ot other */}
  //         <Route path='/course/create/requirements' element={<CourseRequirements />} />
  //         <Route path='/course/create/general' element={<CourseGeneral />} />
  //         <Route path='/course/create/topics' element={<CourseTopics />} />
  //       </Routes>
  //     </BrowserRouter>
  //   </div>
  // );

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          {/* Allow access only to the home page */}
          <Route index element={<Home />} />

          {/* ========== < AUTH > ========== */}
          <Route path='/login' element={<Authentication />} />
          <Route path='/register' element={<Registration />} />
          <Route path='/fp' element={<ForgotPassword />} />

          {/* ========== < FOOTER > ========== */}
          {/* <Route path='/news' element={<News />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/hc' element={<HelpCenter />} /> */}
          <Route path='/about' element={<About />} />
          {/* <Route path='/partners' element={<CompanyPartners />} /> */}
          <Route path='/career' element={<Career />} />
          {/* <Route path='/privacy-policy' element={<PrivacyPolicy />} /> */}

          <Route path='*' element={<PageForbid />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import axios from 'axios';

export async function requestUserLogin({ email, password }) {
  try {
    const response = await axios.post(
      'http://localhost:3002/api/auth/login',
      {
        email: email,
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status === 200) {
      // console.log('> LOGIN TOKEN', response.data.token);
      localStorage.setItem('token', response.data.token);
      window.location.href = '/';
    } else if (response.status === 401) {
      return 401;
    } else if (response.status === 404) {
      return 404;
    } else {
      return 500;
    }
  } catch (err) {}
}

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Styles from './BigSlider.module.scss';
import { Link } from 'react-router-dom';
import { RenderStarsDependOnCourseRating } from '../../common/func/func';
import { BigSliderLoadingAnimation } from '../../common/func/LoadingAnimations';
import { TruncateName } from '../../common/func/func';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Item = ({
  courseImage,
  courseName,
  courseTitle,
  courseRating,
  courseAuthor,
  courseCategory,
  courseHasDiscount,
  courseDescription,
  reviewAmount,
  Price,
}) => {
  return (
    <Link to={`/course/${courseName}`}>
      <div className={Styles.RootCourse}>
        <div>
          <img className={Styles.CoursePreview} src={courseImage} alt='Course image' />
        </div>
        <div className={Styles.CourseWrapperInformation}>
          <h2 className={Styles.CourseTitle}>{courseTitle}</h2>
          <h3 className={Styles.CourseAuthor}>
            {courseAuthor} &#183; {courseCategory}
          </h3>
          <div className={Styles.CourseRating}>
            <h3>{courseRating}</h3>
            {RenderStarsDependOnCourseRating(courseRating)}
            <h4>{reviewAmount} reviews</h4>
          </div>
          <p className={Styles.CourseDescription}>{TruncateName(courseDescription)}</p>
          <div className={Styles.PriceWithDiscount}>
            <h3 className={Styles.Item__Price}>${9.99}</h3>
            <h5 className={Styles.CourseOldPrice}>
              <s>${Price}</s>
            </h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default function BigSlider({ sliderTitle, items }) {
  return (
    <div className={Styles.Root}>
      <div className={Styles.Slider}>
        <h1 className={Styles.SliderTitle}>{sliderTitle}</h1>
      </div>
      <Carousel responsive={responsive}>
        {items && items.length > 0 ? (
          items.map((course, index) => (
            <div key={index}>
              <Item
                courseImage={course.preview_source ?? 'N/A'}
                courseName={course.name ?? 'N/A'}
                courseRating={course.rating ?? 'N/A'}
                courseAuthor={course.instructor ?? 'N/A'}
                courseCategory={course.category ?? 'N/A'}
                reviewAmount={course.students ?? 'N/A'}
                courseTitle={course.name ?? 'N/A'}
                courseDescription={course.description ?? 'N/A'}
                courseHasDiscount={course.courseWithDiscount ?? 'N/A'}
                Price={course.price ?? 'N/A'}
              />
            </div>
          ))
        ) : (
          <BigSliderLoadingAnimation />
        )}
      </Carousel>
    </div>
  );
}

import Styles from './HeaderNotifications.module.scss';
import { useState } from 'react';
import NotificationImage from '../../common/images/HeaderBellIcon.png';
import { Link } from 'react-router-dom';
const NotificationsIsEmpty = () => {
  return (
    <div className={Styles.ShopingCartEmpty}>
      <h1>There are not notifications</h1>
    </div>
  );
};

const DropdownMenu = () => {
  //   const [Notification, setNotification] = useState(1);

  return (
    <div className={Styles.dropdownMenu}>
      <NotificationsIsEmpty />
    </div>
  );
};

export default function NotificationMenu() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <div className={Styles.User}>
      <header className={Styles.Appheader}>
        <div
          className={Styles.UserImage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to='/notifications'>
            <img src={NotificationImage} alt='Notifications' />
          </Link>
          <div
            className={`${Styles.dropdownMenu} ${
              isDropdownVisible ? Styles.dropdownMenuVisible : ''
            }`}
          >
            {/* Dropdown content goes here */}
            <NotificationsIsEmpty />
          </div>
        </div>
      </header>
    </div>
  );
}

export function ValidateLoginUserForm({ email, password }) {
  if (!email || !password) {
    //  return false;
  }

  return true;
}

export function ValidateRegistrationUserForm(Username, email, password) {
  if (!Username || !email || !password) {
    return false;
  }

  // Validate that Username consists of exactly two words (first and last name)
  const nameParts = Username.trim().split(/\s+/); // Split by one or more spaces
  if (nameParts.length !== 2) {
    return false; // Username must have exactly two words
  }

  return true;
}

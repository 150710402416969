import Styles from './ReviewSlider.module.scss';
import React from 'react';
import Carousel from 'react-multi-carousel';
import QuotePNG from '../../common/images/quote.png';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 2000, min: 1800 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1224 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1224, min: 464 },
    items: 1,
  },
};

const Item = ({
  reviewAuthorImage,
  reviewAuthorName,
  reviewAuthorOccupation,
  reviewAuthorText,
}) => {
  return (
    <div className={Styles.Item}>
      <div className={Styles.Item__review}>
        <img src={QuotePNG} alt='author review'></img>
      </div>

      <div className={Styles.ReviewContent}>
        <img src={reviewAuthorImage}></img>

        <div className={Styles.ReviewAuthor}>
          <h1>{reviewAuthorName}</h1>
          <h2>{reviewAuthorOccupation}</h2>
        </div>
      </div>
      <div className={Styles.ReviewText}>
        <p>{reviewAuthorText}</p>
      </div>
    </div>
  );
};

const Slider = ({ items }) => {
  return (
    <div className={Styles.ItemsSlider}>
      <Carousel responsive={responsive}>
        {items && items.length > 0 ? (
          items.map((review, index) => (
            <div key={index}>
              <Item
                reviewAuthorImage={review.reviewAuthorImage ?? 'N/A'}
                reviewAuthorOccupation={review.reviewAuthorOccupation ?? 'N/A'}
                reviewAuthorName={review.reviewAuthorName ?? 'N/A'}
                reviewAuthorText={review.reviewAuthorText ?? 'N/A'}
              />
            </div>
          ))
        ) : (
          <></>
        )}
      </Carousel>
    </div>
  );
};

export default Slider;

import Styles from './CoursesMenu.module.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RenderDevelopmentsCatergoeies() {
  const { t } = useTranslation();

  return (
    <div className={Styles.SubCategoriesRoot}>
      <Link className={Styles.CategoryTitle} to='/courses/development'>
        {t('category_development')}
      </Link>
      <div className={Styles.NestedCourseCategories}>
        <Link to='/courses/development/rust'>Rust</Link>
        <Link to='/courses/development/react'>React</Link>
        <Link to='/courses/development/python'>Python</Link>
        <Link to='/courses/development/cplusplus'>C++</Link>
      </div>
    </div>
  );
}

function RenderBusinessCatergoeies() {
  const { t } = useTranslation();

  return (
    <div className={Styles.SubCategoriesRoot}>
      <Link className={Styles.CategoryTitle} to='/courses/business'>
        {t('category_business')}
      </Link>
      <div className={Styles.NestedCourseCategories}>
        <Link to='/courses/business/startups'>Star Ups</Link>
        <Link to='/courses/business/grows'>Grows</Link>
      </div>
    </div>
  );
}

function RenderLanguagesCatergoeies() {
  const { t } = useTranslation();

  return (
    <div className={Styles.SubCategoriesRoot}>
      <Link className={Styles.CategoryTitle} to='/courses/languages'>
        {t('category_languages')}
      </Link>
      <div className={Styles.NestedCourseCategories}>
        <Link to='/courses/languages/English'>English</Link>
        <Link to='/courses/languages/Russian'>Russian</Link>
      </div>
    </div>
  );
}

function RenderCoursesDropDown() {
  const [activeCategory, setActiveCategory] = useState(null);
  const { t } = useTranslation();

  return (
    <div className={Styles.Root}>
      <Link to='/courses/all' className={Styles.HeaderTitle}>
        {t('header_courses')}
      </Link>
      <div className={Styles.MainDropDownContant}>
        <RenderDevelopmentsCatergoeies />
        <RenderBusinessCatergoeies />
        <RenderLanguagesCatergoeies />
      </div>
    </div>
  );
}

export default function CoursesMenu() {
  return (
    <>
      <RenderCoursesDropDown />
    </>
  );
}

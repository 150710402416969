import Illustrate from '../../common/images/ForbidIllustrate.svg';
import Header from '../../components/header/header';
import Footer from '../../components/Footer/Footer';
import Styles from './Common.module.scss';
import { useEffect } from 'react';
//

export default function PageForbid() {
  useEffect(() => {
    document.title = 'Technical work | Ednely';
  });

  return (
    <>
      <Header />
      <div className={Styles.Root}>
        <h1>Sorry, we're on technical work</h1>
        <h2>We'll be back soon!</h2>
        <img src={Illustrate} />
      </div>
      <Footer />
    </>
  );
}

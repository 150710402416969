import Styles from './UserMenu.module.scss';
import { useState } from 'react';
import UserImage from '../../../../common/images/TyrellWellick.jpeg';
import { Link } from 'react-router-dom';

import LanguageModal from './LanguagesModal/LanguageModal';
// import SettingsModal from './SettingsModal/SettingsModal.tsx';

import { handleUserLogOut } from '../../../../common/func/func';

function DropDownMenuHeader({ UserName, UserEmail }) {
  return (
    <div className={Styles.DropDownHeader}>
      <img className={Styles.DropDownHeaderImage} src={UserImage}></img>
      <Link to='/account'>
        <div className={Styles.DropDownHeaderUser}>
          <h3>{UserName}</h3>
          <h5>{UserEmail}</h5>
        </div>
      </Link>
    </div>
  );
}

function DropdownMenu({ isVisible }) {
  return (
    <div className={`${Styles.dropdownMenu} ${isVisible ? Styles.dropdownMenuVisible : ''}`}>
      <DropDownMenuHeader UserName={'Tyrell Wellick'} UserEmail={'quiedcpp@gmail.com'} />
      <hr />
      <Link to='/account'>Account</Link>
      <Link to='/enrollments'>My courses</Link>
      <Link to='/wishlist'>WishList</Link>
      <hr />
      <Link to='/notifications'>Notifications</Link>
      <Link to='/announcements'>Announcements</Link>
      <Link to='/news'>News</Link>
      <hr />
      <LanguageModal />
      {/* <SettingsModal /> */}
      <button onClick={handleUserLogOut}>Log out</button>
    </div>
  );
}

export default function UserHeaderContent() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  return (
    <div className={Styles.User}>
      <header>
        <div
          className={Styles.HeaderUserImage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to='/account'>
            <img src={UserImage} alt='User Image' />
          </Link>
          {/* Dropdown menu now inside the same container */}
          <DropdownMenu isVisible={isDropdownVisible} />
        </div>
      </header>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/AboutCompanyHeader/AboutCompanyHeader';
import Styles from './Career.module.scss';
import Illustrate from '../../common/images/IllustrateCareers.svg';

function Career() {
  useEffect(() => {
    document.title = 'Career | Ednely';
  });

  function PageSummary() {
    return (
      <div className={Styles.PageSummary}>
        <h1>Hi! We're glad to see you here.</h1>
        <h2>We are looking for talented and creative people to join our team.</h2>
        <p>Now we interested in WEB Developers (React.js, Node.js), Designers, SMM Specialists.</p>
        <p>Experience is not necessary!</p>
        <p>
          If you are interested in working with us, please contact us by e-mail -
          commerce@ednely.com
        </p>
      </div>
    );
  }

  function TopPage() {
    return (
      <div className={Styles.PageTop}>
        <div className={Styles.PageTopText}>
          <h3>Join our team!</h3>
          <h1>Collaborate with us to make innovative solutions more accessible for everyone.</h1>
        </div>
        <img src={Illustrate} />
      </div>
    );
  }

  function ActiveCareers() {
    return <div className={Styles.ActiveCareers}></div>;
  }

  return (
    <>
      <Header />
      <div className={Styles.Root}>
        <TopPage />
        <ActiveCareers />
        <PageSummary />
      </div>
      <Footer />
    </>
  );
}

export default Career;

import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Styles from './LanguageModal.module.scss';
import i18n from '../../../../../i18n';
import { useTranslation } from 'react-i18next';

function LanguageButton({ Title }) {
  return (
    <div className={Styles.LanguageButton}>
      <h1>{Title}</h1>
    </div>
  );
}

const LanguageModal = () => {
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [modal2Open, setModal2Open] = useState(false);

  const chooseLanguage = (e) => {
    e.preventDefault();
    i18n.changeLanguage(e.target.value); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(e.target.value);
    setModal2Open(false);
  };

  return (
    <>
      <Button className={Styles.ModalButton} type='primary' onClick={() => setModal2Open(true)}>
        {t('button_language')}
      </Button>
      <Modal
        className={Styles.ModalNestedButtons}
        title='Languages'
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <div className={Styles.TranslateButtonWrapper}>
          <button onClick={chooseLanguage} value='en'>
            English
          </button>
          <button onClick={chooseLanguage} value='ar'>
            اللغة العربية
          </button>
          <button onClick={chooseLanguage} value='ua'>
            Українська
          </button>
          <button onClick={chooseLanguage} value='ru'>
            Русский
          </button>
        </div>
      </Modal>
    </>
  );
};
export default LanguageModal;

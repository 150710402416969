import React, { useState, useEffect } from 'react';
import Styles from './AboutCompanyHeader.module.scss';
import Logo from '../../common/images/LogoBlack.png';
import HambPng from '../../common/images/hamburger.png';
import IllSearch from '../../common/images/HeaderInputSearchIcon.png';
import CompanyDrawer from './CompanyDrawer';

import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

export default function Header() {
  const [open, setOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 600);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderLargeScreenMenu = () => (
    <div className={Styles.HeaderNavigateLinks}>
      <a href='/about'>About us</a>
      <a href='/news'>News</a>
      <a href='/blog'>Blog</a>
      <a href='/contact'>Contact us</a>
      <a href='/career'>Career</a>
      <a href='/partners'>Partners</a>
    </div>
  );

  return (
    <div className={Styles.Header}>
      <a href='/' className={Styles.logo}>
        <img src={Logo} alt='Logo'></img>
      </a>
      {!isSmallScreen && renderLargeScreenMenu()}
      <div className={Styles.SmallSceenButtons}>
        <img className={Styles.SmallScreenMenu} src={IllSearch} />
        <button className={Styles.OpenDrawerButton} onClick={showDrawer}>
          <img src={HambPng} />
        </button>
      </div>

      <CompanyDrawer onClose={onClose} open={open} />
    </div>
  );
}
